import { type Customer$result, CustomerStore } from '$houdini';
import { get, readonly, writable } from 'svelte/store';
import { customerSessionStore } from './customerSession.store';
import { getCountry, getLanguage } from '$lib/utils/i18n';
import posthog from 'posthog-js';
import { browser } from '$app/environment';

export type Customer = NonNullable<Customer$result>['customer'];

const createCustomerStore = () => {
	const customer = writable<Customer | undefined>(undefined);

	const fetchCustomer = () => {
		const customerAccessToken: string | undefined = get(customerSessionStore).data?.accessToken;
		if (!customerAccessToken) return;

		const customerStore = new CustomerStore();
		customerStore
			.fetch({
				variables: {
					country: getCountry(),
					language: getLanguage(),
					customerAccessToken: customerAccessToken
				},
				policy: 'NetworkOnly'
			})
			.then((response) => {
				if (!response?.data) return;

				customer.set(response.data.customer);
			});
	};

	customerSessionStore.subscribe((customerSession) => {
		if (!customerSession.data?.accessToken) {
			customer.set(undefined);
		} else {
			fetchCustomer();
		}
	});

	if (!get(customer)) {
		fetchCustomer();
	}

	const refreshCustomer = () => {
		fetchCustomer();
	};

	if (browser) {
		customer.subscribe(($customer) => {
			if ($customer?.email) {
				posthog.identify($customer.id.replace('gid://shopify/Customer/', ''), {
					email: $customer.email,
					name:
						$customer.firstName && $customer.lastName
							? `${$customer.firstName} ${$customer.lastName}`
							: undefined
				});
			} else {
				posthog.reset();
			}
		});
	}

	return {
		...readonly(customer),
		setCustomer: (value: Customer) => {
			customer.set(value);
		},
		refresh: () => {
			console.log('refreshing customer');
			refreshCustomer();
		},
		reset: () => {
			customer.set(undefined);
		}
	};
};

export const customer = createCustomerStore();
