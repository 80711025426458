export default {
    "name": "Customer",
    "kind": "HoudiniQuery",
    "hash": "2821760dc2eeed3e22a1d665fd3ad4d6073cd245685093078ec729ad6302a817",

    "raw": `query Customer($customerAccessToken: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  customer(customerAccessToken: $customerAccessToken) {
    email
    firstName
    createdAt
    id
    lastName
    phone
    displayName
    birthday: metafield(key: "birthday", namespace: "my_fields") {
      key
      value
      id
    }
    gender: metafield(key: "gender", namespace: "my_fields") {
      key
      value
      id
    }
    addresses(first: 10) {
      edges {
        node {
          zip
          provinceCode
          province
          phone
          name
          longitude
          latitude
          lastName
          id
          formattedArea
          formatted
          firstName
          countryCodeV2
          countryCodeV2
          country
          company
          city
          address2
          address1
        }
      }
    }
    defaultAddress {
      zip
      provinceCode
      province
      phone
      name
      longitude
      latitude
      lastName
      id
      formattedArea
      formatted
      firstName
      countryCodeV2
      countryCodeV2
      country
      company
      city
      address2
      address1
    }
    acceptsMarketing
    orders(first: 99) {
      edges {
        node {
          id
        }
      }
    }
  }
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "customer": {
                "type": "Customer",
                "keyRaw": "customer(customerAccessToken: $customerAccessToken)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "email": {
                            "type": "String",
                            "keyRaw": "email",
                            "nullable": true,
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "createdAt": {
                            "type": "DateTime",
                            "keyRaw": "createdAt",
                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "phone": {
                            "type": "String",
                            "keyRaw": "phone",
                            "nullable": true,
                            "visible": true
                        },

                        "displayName": {
                            "type": "String",
                            "keyRaw": "displayName",
                            "visible": true
                        },

                        "birthday": {
                            "type": "Metafield",
                            "keyRaw": "birthday(key: \"birthday\", namespace: \"my_fields\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "gender": {
                            "type": "Metafield",
                            "keyRaw": "gender(key: \"gender\", namespace: \"my_fields\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "addresses": {
                            "type": "MailingAddressConnection",
                            "keyRaw": "addresses(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "MailingAddressEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "MailingAddress",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "zip": {
                                                                "type": "String",
                                                                "keyRaw": "zip",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "provinceCode": {
                                                                "type": "String",
                                                                "keyRaw": "provinceCode",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "province": {
                                                                "type": "String",
                                                                "keyRaw": "province",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "phone": {
                                                                "type": "String",
                                                                "keyRaw": "phone",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "longitude": {
                                                                "type": "Float",
                                                                "keyRaw": "longitude",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "latitude": {
                                                                "type": "Float",
                                                                "keyRaw": "latitude",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "lastName": {
                                                                "type": "String",
                                                                "keyRaw": "lastName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "formattedArea": {
                                                                "type": "String",
                                                                "keyRaw": "formattedArea",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "formatted": {
                                                                "type": "String",
                                                                "keyRaw": "formatted",
                                                                "visible": true
                                                            },

                                                            "firstName": {
                                                                "type": "String",
                                                                "keyRaw": "firstName",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "countryCodeV2": {
                                                                "type": "CountryCode",
                                                                "keyRaw": "countryCodeV2",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "country": {
                                                                "type": "String",
                                                                "keyRaw": "country",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "company": {
                                                                "type": "String",
                                                                "keyRaw": "company",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "city": {
                                                                "type": "String",
                                                                "keyRaw": "city",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "address2": {
                                                                "type": "String",
                                                                "keyRaw": "address2",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "address1": {
                                                                "type": "String",
                                                                "keyRaw": "address1",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "defaultAddress": {
                            "type": "MailingAddress",
                            "keyRaw": "defaultAddress",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "provinceCode": {
                                        "type": "String",
                                        "keyRaw": "provinceCode",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "province": {
                                        "type": "String",
                                        "keyRaw": "province",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "phone": {
                                        "type": "String",
                                        "keyRaw": "phone",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "longitude": {
                                        "type": "Float",
                                        "keyRaw": "longitude",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "latitude": {
                                        "type": "Float",
                                        "keyRaw": "latitude",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "formattedArea": {
                                        "type": "String",
                                        "keyRaw": "formattedArea",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "formatted": {
                                        "type": "String",
                                        "keyRaw": "formatted",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "countryCodeV2": {
                                        "type": "CountryCode",
                                        "keyRaw": "countryCodeV2",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "company": {
                                        "type": "String",
                                        "keyRaw": "company",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "address2": {
                                        "type": "String",
                                        "keyRaw": "address2",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "address1": {
                                        "type": "String",
                                        "keyRaw": "address1",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "acceptsMarketing": {
                            "type": "Boolean",
                            "keyRaw": "acceptsMarketing",
                            "visible": true
                        },

                        "orders": {
                            "type": "OrderConnection",
                            "keyRaw": "orders(first: 99)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "OrderEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Order",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "customerAccessToken": "String",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=3fd32a1c7f7f42a712b9c2531f048602db64e154585fe522e85c75d796809af5";